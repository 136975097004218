import _ from 'lodash';
/* eslint-disable max-len */
import React, { useEffect, useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { InputField, DropdownSelectField, CheckboxField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { isNotEmptyOrZero } from '../../../../utils/CurrencyAmountUtils';
import CurrencyInputComponent from '../../../CurrencyInput/CurrencyInput';
import DatePickerComponent from '../../../DatePicker/DatePicker';
import useTypelistHandler from '../../../../hooks/useTypelistHandler';
import metadata from './ContentEstimateComponent.metadata.json5';
import styles from './ContentEstimateComponent.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './ContentEstimateComponent.messages';

function ContentEstimateComponent(props) {
    const {
        id,
        repairDTO,
        onValidate,
        writeValue
    } = props;

    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('ContentEstimateComponent');

    const translator = useContext(TranslatorContext);

    const generateNewContentItem = useCallback(() => {
        let existingItems = repairDTO.detailedContentEstimates.value;
        if (existingItems === undefined) {
            existingItems = [];
        }

        existingItems.push({
            selectedForRemoval: false
        });

        writeValue(existingItems, 'detailedContentEstimates');
    }, [repairDTO.detailedContentEstimates.value, writeValue]);

    useEffect(() => {
        if (repairDTO.detailedContentEstimates.value === undefined) {
            generateNewContentItem();
        }
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const removeSelectedItems = useCallback(() => {
        let existingItems = repairDTO.detailedContentEstimates.value;
        if (existingItems === undefined) {
            existingItems = [];
        }

        const itemsToNotBeRemoved = existingItems.filter((item) => item.selectedForRemoval === false);
        writeValue(itemsToNotBeRemoved, 'detailedContentEstimates');
    }, [repairDTO.detailedContentEstimates.value, writeValue]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;

        if (repairDTOValue.detailedContentEstimatesPresent === true) {
            return repairDTOValue.detailedContentEstimates.find((contentItem) => {
                return contentItem.category === undefined
                    || _.isEmpty(contentItem.name)
                    || _.isEmpty(contentItem.description)
                    || _.isEmpty(contentItem.quantity)
                    || contentItem.purchaseDate === undefined
                    || contentItem.currentValue === undefined;
            }) === undefined;
        }

        return true;
    }, [repairDTO.value]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const retrieveAvailableValues = useCallback(() => {
        return getValuesFromTypelistIncludeFilter(
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ContentEstimateItem',
            'category'
        );
    }, [getValuesFromTypelistIncludeFilter]);

    const generateOverrides = useCallback(() => {
        const existingItems = repairDTO.detailedContentEstimates.value;
        if (existingItems === undefined) {
            return {};
        }

        const generated = existingItems.map((value, index) => {
            const newOverride = {
                [`itemCategory${index}`]: {
                    availableValues: retrieveAvailableValues()
                }
            };
            return newOverride;
        });
        return Object.assign({}, ...generated);
    }, [repairDTO.detailedContentEstimates.value, retrieveAvailableValues]);

    const renderDropdownCell = useCallback((dtoValue, index, fieldSetup) => {
        const shouldShowRequired = fieldSetup.required === 'true'
            && (dtoValue[fieldSetup.path] === undefined || _.isEmpty(dtoValue[fieldSetup.path]));

        return (
            <DropdownSelectField
                id={`${fieldSetup.id}_${index}`}
                path={fieldSetup.path}
                availableValues={retrieveAvailableValues()}
                value={dtoValue[fieldSetup.path]}
                showErrors={shouldShowRequired}
                validationMessages={shouldShowRequired ? [''] : []}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
                hideLabel
            />
        );
    }, [retrieveAvailableValues, writeValue]);

    const renderTextCell = useCallback((dtoValue, index, fieldSetup) => {
        const shouldShowRequired = fieldSetup.required === 'true'
            && (dtoValue[fieldSetup.path] === undefined || _.isEmpty(dtoValue[fieldSetup.path]));

        return (
            <InputField
                id={`${fieldSetup.id}_${index}`}
                path={fieldSetup.path}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
                value={dtoValue[fieldSetup.path]}
                maxLength={fieldSetup.maxLength}
                showErrors={shouldShowRequired}
                validationMessages={shouldShowRequired ? [''] : []}
                hideLabel
            />
        );
    }, [writeValue]);

    const renderNumberCell = useCallback((dtoValue, index, fieldSetup) => {
        const shouldShowRequired = fieldSetup.required === 'true'
            && (dtoValue[fieldSetup.path] === undefined || _.isEmpty(dtoValue[fieldSetup.path]));

        return (
            <InputField
                id={`${fieldSetup.id}_${index}`}
                path={fieldSetup.path}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
                value={dtoValue[fieldSetup.path]}
                showErrors={shouldShowRequired}
                validationMessages={shouldShowRequired ? [''] : []}
                minValue={0}
                inputType="number"
                hideLabel
            />
        );
    }, [writeValue]);

    const renderAmountCell = useCallback((dtoValue, index, fieldSetup) => {
        const shouldShowRequired = fieldSetup.required === 'true'
            && (dtoValue[fieldSetup.path] === undefined || !isNotEmptyOrZero(dtoValue[fieldSetup.path]));

        return (
            <CurrencyInputComponent
                id={`${fieldSetup.id}_${index}`}
                path={fieldSetup.path}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
                value={dtoValue[fieldSetup.path]}
                showErrors={shouldShowRequired}
                validationMessages={shouldShowRequired ? [''] : []}
                hideLabel
            />
        );
    }, [writeValue]);

    const renderDateCell = useCallback((dtoValue, index, fieldSetup) => {
        const shouldShowRequired = fieldSetup.required === 'true'
            && (dtoValue[fieldSetup.path] === undefined || _.isEmpty(dtoValue[fieldSetup.path]));

        return (
            <DatePickerComponent
                id={`${fieldSetup.id}_${index}`}
                path={fieldSetup.path}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
                value={dtoValue[fieldSetup.path]}
                showErrors={shouldShowRequired}
                validationMessages={shouldShowRequired ? [''] : []}
                showTime={false}
                format="vshort"
                hideLabel
            />
        );
    }, [writeValue]);

    const renderCheckboxCell = useCallback((dtoValue, index, fieldSetup) => {
        return (
            <CheckboxField
                id={`${fieldSetup.id}_${index}`}
                name={`${fieldSetup.id}_${index}`}
                value={dtoValue[fieldSetup.path]}
                onValueChange={(value) => writeValue(value, `detailedContentEstimates[${index}].${fieldSetup.path}`)}
            />
        );
    }, [writeValue]);

    const overrideProps = {
        contentEstimateGeneral: {
            visible: repairDTO.detailedContentEstimatesPresent.value === false
        },
        contentEstimateDetailed: {
            visible: repairDTO.detailedContentEstimatesPresent.value === true
        },
        contentEstimateRemoveSelectedItems: {
            disabled: repairDTO.detailedContentEstimates.value === undefined
                || _.isEmpty(repairDTO.detailedContentEstimates.value)
                || repairDTO.detailedContentEstimates.value.find((item) => item.selectedForRemoval === true) === undefined
        },
        contentEstimateDetailedGrid: {
            visible: false
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            generateNewContentItem,
            removeSelectedItems,
            renderDropdownCell,
            renderNumberCell,
            renderAmountCell,
            renderDateCell,
            renderCheckboxCell,
            renderTextCell
        },
        resolveComponentMap: {}
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
                onValueChange={writeValue}
            />
        </div>
    );
}

export default ContentEstimateComponent;
